import Vue from 'vue'
/**
 * 留资指令
 * 实现简单的留资判断并跳转
 * @param {String} source 留资用的source(必传)
 */

Vue.directive('leave-info', {
  update(el, binding, vNode) {
    vNode.context.$params = binding?.value
  },
  inserted: function (el, binding) {
    const source = binding?.value
    el.handle = function () {
      Vue.prototype.$leaveInfo.doLeave({ source })
    }
    el.addEventListener('click', el.handle, false)
  }
})
