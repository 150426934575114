import DirectiveBindPhonePlugin from './utils/plugins/directive-bind-phone.js'
import PrototypeBindPhonePlugin from './utils/plugins/prototype-bind-phone.js'
import BindPhoneDirective from './utils/directives/bind-phone'
import BindPhoneDialog from './components/BindPhoneDialog.vue'
import './assets/style.css'
import './utils/prototype/env.js'

export {
  DirectiveBindPhonePlugin,
  BindPhoneDirective,
  BindPhoneDialog,
  PrototypeBindPhonePlugin
}

const TestBindPhone = (Vue) => {
  Vue.use(DirectiveBindPhonePlugin)
  Vue.use(PrototypeBindPhonePlugin)
}

export default TestBindPhone
