import { defaultLocalPageData } from '@/utils'
import VisitedModelTransform from './transform'

/**
 * 已访问页面相关操作，提供查询，存储功能
 */
class VisitedInfo {
  constructor(key = 'visited-path-list') {
    this._CACHE_KEY = key
    this.visitedList = []
    this.refreshVisited()
  }

  refreshVisited() {
    const expiresTime = 3 * 24 * 60 * 60 * 1000
    const now = Date.now()
    const TransformModel = new VisitedModelTransform(
      defaultLocalPageData(this._CACHE_KEY, [])
    )
    this.visitedList = TransformModel.list.filter((item) => {
      return now - item.timestamp <= expiresTime
    })
    this.visitedList = TransformModel.transform(this.visitedList)
    localStorage.setItem('visited-path-list', JSON.stringify(this.visitedList))
  }

  comparePath(basePath, newPath) {
    const pathSame = basePath.type === newPath.type
    const searchSame = basePath.id === newPath.id

    return pathSame && searchSame
  }

  pathVisitedIndex(item) {
    const list = this.visitedList
    let sameIndex = -1
    if (list) {
      for (let i = 0; i < list.length; i++) {
        const same = this.comparePath(list[i], item)
        if (same) {
          sameIndex = i
          break
        }
      }
    }
    return sameIndex
  }

  check(item) {
    return this.pathVisitedIndex(item) !== -1
  }

  push({ type, id = null }) {
    if (!id) {
      return
    }
    // 判断是否重复
    const item = this.createItem(type, id)
    const index = this.pathVisitedIndex(item)
    const visitedList = [].concat(this.visitedList)
    // 已存在
    if (index !== -1) {
      visitedList.splice(index, 1)
    }
    visitedList.unshift(item)
    this.visitedList = [].concat(visitedList)
    this.save()
  }

  createItem(type, id) {
    return {
      type,
      id,
      timestamp: Date.now()
    }
  }

  save() {
    localStorage.setItem('visited-path-list', JSON.stringify(this.visitedList))
  }
}

export default VisitedInfo
