import qs from 'qs'
import request from './index'

// 获取图形验证码
export function getPhoneCaptcha() {
  return request({
    url: `/jypay/user/phone/imgCaptcha?t=${Date.now()}`,
    method: 'GET'
  })
}

// 发送短信验证码/手机号绑定
export function setPhoneBind(data, type) {
  data = qs.stringify(data)
  return request({
    url: `/jypay/user/phone/${type}`,
    method: 'POST',
    data
  })
}

// 广告获取
export function ajaxGetAD(data) {
  return request({
    url: '/publicapply/free/getJyAdList',
    method: 'post',
    data
  })
}
