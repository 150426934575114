import Vue from 'vue'
import { on } from '@/utils/domEvent/'

const nodeList = []
const ctx = '@@clickoutsideContext'

let startClick
let seed = 0

!Vue.prototype.$isServer && on(document, 'mousedown', (e) => (startClick = e))

!Vue.prototype.$isServer &&
  on(document, 'mouseup', (e) => {
    nodeList.forEach((node) => node[ctx].documentHandler(e, startClick))
  })

function createDocumentHandler(el, binding, vNode) {
  return function (mouseup = {}, mousedown = {}) {
    if (
      !vNode ||
      !vNode.context ||
      !mouseup.target ||
      !mousedown.target ||
      el.contains(mouseup.target) ||
      el.contains(mousedown.target) ||
      el === mouseup.target ||
      (vNode.context.popperElm &&
        (vNode.context.popperElm.contains(mouseup.target) ||
          vNode.context.popperElm.contains(mousedown.target)))
    )
      return

    if (
      binding.expression &&
      el[ctx].methodName &&
      vNode.context[el[ctx].methodName]
    ) {
      vNode.context[el[ctx].methodName]()
    } else {
      el[ctx].bindingFn && el[ctx].bindingFn()
    }
  }
}

/**
 * v-clickoutside
 * @desc 点击元素外面才会触发的事件
 * @example
 * ```vue
 * <div v--click-outside="handleClose">
 * ```
 */
Vue.directive('click-outside', {
  bind(el, binding, vNode) {
    nodeList.push(el)
    const id = seed++
    el[ctx] = {
      id,
      documentHandler: createDocumentHandler(el, binding, vNode),
      methodName: binding.expression,
      bindingFn: binding.value
    }
  },

  update(el, binding, vNode) {
    el[ctx].documentHandler = createDocumentHandler(el, binding, vNode)
    el[ctx].methodName = binding.expression
    el[ctx].bindingFn = binding.value
  },

  unbind(el) {
    const len = nodeList.length

    for (let i = 0; i < len; i++) {
      if (nodeList[i][ctx].id === el[ctx].id) {
        nodeList.splice(i, 1)
        break
      }
    }
    delete el[ctx]
  }
})
