import { defaultLocalPageData } from '@/utils'

/**
 * 当前所有已查看页面匹配规则
 * @type {{project: string, ent: string, issued: string, content: string, buyer: string}}
 */
const Rules = {
  content: 'article/content/*',
  issued: 'article/issued',
  ent: 'ent(_ser)?_portrait',
  buyer: 'client_portrayal',
  project: 'client_follow_detail'
}

/**
 * 旧数据模型转换处理，同时兼容新旧模型使用
 */
class VisitedModelTransform {
  constructor(list = defaultLocalPageData('visited-path-list', [])) {
    this.list = list
    return this
  }

  checkPathRules(path) {
    let result = path
    for (const type in Rules) {
      if (new RegExp(Rules[type]).test(path)) {
        result = type
        break
      }
    }
    return result
  }

  transformItem(item) {
    return {
      id: item?.id || item?.search.replace(/(id|sid|entName)=/, ''),
      type: item?.type || this.checkPathRules(item?.path),
      path: item?.path,
      timestamp: item?.timestamp
    }
  }

  transform(list = this.list) {
    return list.map(this.transformItem.bind(this))
  }
}

export default VisitedModelTransform
