import BindPhoneDialog from '../../components/BindPhoneDialog.vue'
import BindPhoneDirective from '../../utils/directives/bind-phone.js'
import '../../assets/style.css'

const DirectiveBindPhonePlugin = {
  install(Vue) {
    // 注册全局组件
    Vue.component('bind-phone-dialog', BindPhoneDialog)
    // 注册全局指令
    Vue.directive('bound-phone', BindPhoneDirective)

    const DialogConstructor = Vue.extend(BindPhoneDialog)
    Vue.prototype.$bindPhoneDialog = function (options) {
      options.props.visible = true
      const instance = new DialogConstructor({
        propsData: options.props
      })
      if (options.on) {
        Object.keys(options.on).forEach((event) => {
          instance.$on(event, options.on[event])
        })
      }
      // 支持插槽内容
      if (options.slots) {
        Object.keys(options.slots).forEach((slotName) => {
          instance.$slots[slotName] = options.slots[slotName]
        })
      }
      instance.$mount()
      document.body.appendChild(instance.$el)
      instance.visible = true
      // 添加销毁钩子
      instance.$on('close', () => {
        // 关闭动画结束后再销毁
        setTimeout(() => {
          instance.$el.parentNode.removeChild(instance.$el)
          instance.$destroy()
        }, 300)
      })
      // 弹框弹起时埋点abtest
      try {
        window.__EasyJTrack.addTrack(options.props.name, {
          break_data: 'abtest',
          source: options.props.name
        })
      } catch (error) {}
      return instance
    }
  }
}

export default DirectiveBindPhonePlugin
