import Vue from 'vue'
import { Toast, Dialog } from 'vant'
import { KeepLabel } from '@/ui'
import {
  ajaxSetUserKeep,
  ajaxGetKeepLabels,
  ajaxSetDiyLabel,
  ajaxSetUserKeepLabels
} from '@/api/modules'
import router from '@/router'

const KeepDialogConstructor = Vue.extend(KeepLabel)

/**
 * 用户标讯收藏
 */
class UserKeepLabel {
  constructor(config = {}) {
    this.config = Object.assign(
      {
        status: false,
        id: '',
        getContainer: 'body',
        loadingTip: '提交中',
        showLabel: true,
        stopLeaveInfo: false,
        source: 'article_collection'
      },
      config
    )
    this.methods = {
      open: this.onOpen.bind(this),
      complete: this.onComplete.bind(this),
      beforeRedirect: this.onBeforeRedirect.bind(this),
      getLabelAction: this.onGetLabelAction.bind(this),
      addLabelAction: this.onAddLabelAction.bind(this),
      submit: this.onSubmit.bind(this),
      close: this.onClose.bind(this),
      cancel: this.onCancelCollect.bind(this), // 仅取消收藏
      bindTag: this.afterBindTag.bind(this), // 仅绑定标签成功触发
      goUpBuyPage: this.onGoUpBuyPage.bind(this)
    }
    this.components = null
    this.loading = null
  }

  /**
   * 注册标签收藏弹窗组件
   */
  install() {
    console.log('$keep install')
    const KeepDialogNode = new KeepDialogConstructor({
      propsData: {
        addLabelAction: this.methods.addLabelAction,
        getLabelAction: this.methods.getLabelAction,
        checkGroupConfig: {
          findKey: 'lid',
          findLabel: 'lanme'
        }
      }
    })
    this.components = KeepDialogNode
    this.components.$on('open', this.methods.open)
    this.components.$on('close', this.methods.close)
    this.components.$on('submit', this.methods.submit)
    KeepDialogNode.$mount()
    document
      .querySelector(this.config.getContainer)
      .appendChild(KeepDialogNode.$el)
  }

  /**
   * 销毁标签弹窗实例
   */
  destroyed() {
    console.log('$keep destroyed')
    if (this.components) {
      this.components.$destroy()
      this.components.$el.remove()
      this.components = null
    }
    this.showLoading(false)
  }

  /**
   * 初始化标讯收藏弹窗
   * @param config
   */
  initLabelPop(config = {}) {
    this._set(config)
    this.destroyed()
    this.install()
  }

  /**
   * 获取处理后的请求参数
   * @param config
   * @returns {{binfo: {bid: *}[], baction: (string)}}
   * @private
   */
  _getParams(config = {}) {
    this._set(config)
    let tempArr = this.config.id
    if (typeof tempArr === 'string') {
      tempArr = tempArr.split(',')
    }
    tempArr = tempArr.map((v) => ({ bid: v }))
    return {
      baction: this.config.status ? 'R' : 'C',
      binfo: tempArr
    }
  }

  /**
   * 合并配置项
   * @param config
   * @private
   */
  _set(config) {
    if (typeof config === 'object' && Object.keys(config).length) {
      this._setConfig(config)
      this._setMethods(config)
    }
  }

  /**
   * 合并配置项 config
   * @param config
   * @private
   */
  _setConfig(config) {
    Object.assign(this.config, this._filterObject(this.config, config))
  }

  /**
   * 合并配置项 methods
   * @param methods
   * @private
   */
  _setMethods(methods) {
    Object.assign(this.methods, this._filterObject(this.methods, methods))
  }

  /**
   * 过滤对象多余的属性，用于合并
   * @param obj
   * @param tranObj
   * @returns {{}}
   * @private
   */
  _filterObject(obj, tranObj) {
    const temp = {}
    Object.keys(obj).forEach((k) => {
      if (Object.prototype.hasOwnProperty.call(tranObj, k)) {
        temp[k] = tranObj[k]
      }
    })
    return temp
  }

  /**
   * 检查收藏失败返回信息，进行弹窗
   * @param type
   * @param message
   */
  checkErrorTip({ type, message }) {
    if (type) {
      return
    }
    Dialog.close()
    switch (message) {
      case '付费用户收藏已达上限': {
        Dialog.confirm({
          message: '您的标讯收藏上限为5000条，请联系客服人员',
          className: 'j-confirm-dialog',
          overlayClass: 'z-2030',
          showCancelButton: false,
          confirmButtonText: '我知道了'
        }).catch((e) => e)
        break
      }
      case '免费用户收藏已达上限': {
        Dialog.confirm({
          message: '您的标讯收藏上限为100条，请升级为会员服务',
          className: 'j-confirm-dialog',
          overlayClass: 'z-2030',
          confirmButtonText: '去升级'
        })
          .then(() => {
            this.methods.goUpBuyPage()
          })
          .catch((e) => e)
        break
      }
      default: {
        Toast(message)
        break
      }
    }
  }

  /**
   * 展示或销毁 loading
   * @param show - 是否展示
   */
  showLoading(show = true) {
    if (this.loading) {
      this.loading.clear()
      this.loading = null
    }
    if (show) {
      this.loading = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.config.loadingTip
      })
    }
  }

  /**
   * 对外提供入口，用于接受配置项
   * @param config
   */
  async action(config = {}) {
    this._set(config)
    const needLeaveInfo = !this.config.status && !this.config.stopLeaveInfo
    if (needLeaveInfo) {
      await this.checkLeaveInfo()
    }
    this.showLoading()
    this.keepAction()
  }

  /**
   * 留资 $leaveInfo 判断
   * @returns {Promise<void>}
   */
  async checkLeaveInfo() {
    if (typeof Vue.prototype.$leaveInfo?.doLeave === 'function') {
      // 判断是否需要留资
      await Vue.prototype.$leaveInfo.doLeave({
        source: this.config.source,
        beforeRedirect: async (conf, res) => {
          const { retainedCapital: needLeaveInfo } = res
          if (needLeaveInfo) {
            await Vue.prototype.$dialog.confirm({
              className: 'j-confirm-dialog',
              closeOnClickOverlay: true,
              showCancelButton: false,
              message:
                '重要项目一键创建标签收藏！请完善个人信息，为您匹配精准服务',
              confirmButtonText: '立即前往'
            })
          }
          await this.methods.beforeRedirect(conf, res)
        }
      })
    }
  }

  /**
   * 收藏/取消收藏 事务
   * @param config
   */
  keepAction() {
    const param = this._getParams()
    ajaxSetUserKeep(param)
      .then((res) => {
        const result = {
          type: res.error_code === 0 && res.data,
          message: res.error_msg
        }
        this.showLoading(false)
        if (param.baction === 'R') {
          this.methods.cancel()
        }
        this.methods.complete(result, res)
        this.checkErrorTip(result)
        if (result.type && this.config.showLabel && !this.config.status) {
          this.initLabelPop()
        }
      })
      .catch((e) => {
        Toast(e)
        this.methods.complete(
          {
            type: false,
            message: e
          },
          e
        )
      })
  }

  /**
   * 标签收藏提交事务
   * @param keys
   * @param options
   * @param complete
   * @returns {Promise<void>}
   */
  async onSubmit({ keys, keysTags, tagList, options, complete }) {
    console.log('$keep onSubmit')
    ajaxSetUserKeepLabels({
      lids: keys.join(','),
      laction: 'S',
      binfo: this._getParams().binfo
    })
      .then((res) => {
        if (res.error_code === 0 && res.data) {
          Toast('已收藏至“我的-标讯收藏”')
          // keysIds: // 选中的标签的id
          // keysTags: // 选中的标签的列表(包含id和名称)
          // tagList: // 所有标签的列表
          this.methods.bindTag({
            keys,
            keysTags,
            tagList,
            options
          })
          this.destroyed()
        } else {
          Toast(res.error_msg)
        }
      })
      .catch((e) => {
        Toast(e)
      })
      .finally(() => {
        complete()
      })
  }

  /**
   * 标签收藏打开回调
   */
  onOpen() {
    console.log('$keep onOpen')
  }

  /**
   * 标签收藏关闭回调
   */
  onClose() {
    console.log('$keep onClose')
    this.destroyed()
  }

  /**
   * 收藏/取消收藏 事务完成回调
   * @param result
   */
  onComplete(result) {
    console.log('$keep onComplete', result)
  }

  /**
   * 取消收藏回调
   */
  onCancelCollect() {
    console.log('$keep onCancelCollect')
  }

  /**
   * 绑定标签成功后的回调
   */
  afterBindTag(r) {
    console.log('$keep bindTag', r)
  }

  /**
   * 留资 $leaveInfo beforeRedirect 钩子
   * @param conf
   * @param res
   */
  onBeforeRedirect(conf, res) {
    console.log('$keep onBeforeRedirect => $leaveInfo beforeRedirect ')
  }

  /**
   * 新增用户自定义标签事务
   * @param item
   * @param action
   * @returns {Promise<*>}
   */
  async onAddLabelAction(item, action) {
    console.log('$keep onAddLabelAction')
    const result = await ajaxSetDiyLabel({
      name: item.label
    }).catch((e) => {
      Toast(e)
      action()
    })
    if (result.error_code === 0 && result.data) {
      return action({
        lid: result.data,
        lanme: item.label
      })
    } else {
      return action()
    }
  }

  /**
   * 获取用户已存在标签集合
   * @returns {Promise<*[]|T>}
   */
  async onGetLabelAction() {
    console.log('$keep onGetLabelAction')
    const result = await ajaxGetKeepLabels().catch((e) => {
      Toast(e)
      return []
    })
    if (result.error_code === 0 && Array.isArray(result.data)) {
      return result.data.reverse()
    } else {
      return []
    }
  }

  /**
   * 特殊事务 弹窗去升级
   */
  onGoUpBuyPage() {
    router.push({
      path: '/common/order/create/svip',
      query: {
        type: 'buy'
      }
    })
  }
}

Vue.prototype.$keep = new UserKeepLabel()
