import BindPhoneDialog from '../../components/BindPhoneDialog.vue'
import '../../assets/style.css'
import Cookies from 'js-cookie'

const PrototypeBindPhonePlugin = {
  install(Vue) {
    // 注册全局组件
    Vue.component('bind-phone-dialog', BindPhoneDialog)

    // 创建绑定手机号弹框实例的函数封装
    function createBindPhoneDialog(instance, options) {
      if (options.on) {
        Object.keys(options.on).forEach((event) => {
          instance.$on(event, options.on[event])
        })
      }

      instance.$mount()
      document.body.appendChild(instance.$el)
      instance.visible = true
      return instance
    }

    Vue.prototype.$testBindPhone = function (options) {
      const { props = {}, slots, pass, bound, next, close } = options
      props.visible = true
      const needPhoneBound = Cookies.get('EXPERIENCESIGN') === 'experiencing'
      // 绑定成功cookie里塞的值
      const alreadyBind = Cookies.get('EXPERIENCE')
      if (typeof pass === 'function') {
        pass()
        return
      }
      if (needPhoneBound) {
        if (alreadyBind) {
          return next?.()
        }
        const DialogConstructor = Vue.extend(BindPhoneDialog)
        const instance = new DialogConstructor({ propsData: props })

        // 封装插槽处理逻辑
        if (slots) {
          Object.entries(slots).forEach(([slotName, slotContent]) => {
            instance.$slots[slotName] = slotContent
          })
        }
        options.on = {
          bound: () => {
            if (bound && typeof bound === 'function') {
              bound()
            } else {
              typeof next === 'function' && next()
            }
            // 绑定完清除cookie
            Cookies.remove('EXPERIENCESIGN')
            // 绑定成功埋点
            try {
              window.__EasyJTrack.addTrack(props.name, {
                break_data: 'abtest',
                source: props.name + '-绑定成功'
              })
            } catch (error) {}
          },
          close: () => {
            if (typeof close === 'function') {
              close()
            } else {
              Vue.prototype.$toast('请先绑定手机号')
            }
          }
        }
        // 创建对话框实例
        const dialog = createBindPhoneDialog(instance, options)
        // 埋点
        if (window.__EasyJTrack) {
          window.__EasyJTrack.addTrack(props.name, {
            break_data: 'abtest',
            source: props.name
          })
        }
        // 添加销毁钩子
        dialog.$on('close', () => {
          // 关闭动画结束后再销毁
          setTimeout(() => {
            dialog.$el.parentNode.removeChild(dialog.$el)
            dialog.$destroy()
          }, 300)
        })
        return dialog
      } else {
        next?.()
      }
    }
  }
}

export default PrototypeBindPhonePlugin
