import Vue from 'vue'

/**
 * 检查是否已访问，为其新增 css class
 * @param el
 * @param vNode
 */
function checkVisited(el, vNode) {
  if (Vue.prototype.$visited.check(vNode.data.visited)) {
    vNode.context.$nextTick(() => {
      el.classList.add(vNode.data.visited.css)
    })
  }
}

/**
 * 合并参数到 vNode.data
 * @param vNode
 * @param params
 */
function mergeParams(vNode, params) {
  const tranObj =
    typeof params?.value === 'object' ? params?.value : { id: params?.value }
  vNode.data.visited = Object.assign(
    {
      type: params?.arg,
      css: 'visited',
      stopClick: params?.modifiers?.stop || false
    },
    tranObj
  )
}

Vue.directive('visited', {
  update(el, binding, vNode) {
    mergeParams(vNode, binding)
    checkVisited(el, vNode)
  },
  inserted: function (el, binding, vNode) {
    mergeParams(vNode, binding)
    checkVisited(el, vNode)
    // 是否阻止点击自动缓存
    if (!vNode.data.visited.stopClick) {
      function onClick(e) {
        Vue.prototype.$visited.push(vNode.data.visited)
        checkVisited(el, vNode)
      }

      el.addEventListener('click', onClick, false)
    }
  }
})
