<template>
  <layout class="no-select">
    <template v-slot:default>
      <!-- <transition :name="transitionName"> -->
      <keep-alive :include="keepAliveList" :max="10">
        <router-view class="router j-container" :class="env.platform" />
      </keep-alive>
      <!-- </transition> -->
    </template>
  </layout>
</template>
<script>
import layout from '@/components/common/layout.vue'
import { fixH5BackRefresh } from '@/utils/'

export default {
  name: 'App',
  components: {
    layout
  },
  data() {
    return {
      env: this.$env
    }
  },
  computed: {
    transitionName() {
      return this.$store.state.direction
    },
    keepAliveList() {
      return this.$store.state.keepAliveList
    }
  },
  created() {
    this.initKeepAlive()
    this.$router.beforeResolve((to, from, next) => {
      if (to.name) {
        this.removeLoading()
      }
      next()
    })
  },
  mounted() {
    this.addHTMLClass()
    this.bindEventsForH5()
    this.bindEventsForApp()
  },
  methods: {
    removeLoading() {
      document.body.classList.remove('app-loading')
    },
    addHTMLClass() {
      if (this.$envs.inH5) {
        // 修复h5下浏览器滑动高度变化，导致页面元素显示不全的问题
        document.querySelector('html').classList.add('h5-html')
      }
    },
    bindEventsForH5() {
      if (this.$envs.inH5) {
        fixH5BackRefresh()
      }
    },
    bindEventsForApp() {
      if (this.$envs.inApp) {
        window.afterClickBack = this.noop
      }
    },
    checkKeepAlive(route) {
      if (route?.meta?.keepAlive) {
        this.$store.commit('keepAlive', route.name)
      }
      if (route?.children) {
        route.children.forEach(this.checkKeepAlive)
      }
    },
    initKeepAlive() {
      const { routes = [] } = this.$router.options
      routes.forEach(this.checkKeepAlive)
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC,
    Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #171826;
  width: 100%;
  height: 100%;
  position: relative;
}
.router {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
