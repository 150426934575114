import Vue from 'vue'

Vue.directive('active', {
  inserted: function (el, binding) {
    const params = Object.assign(
      {
        css: 'active',
        time: -1
      },
      binding?.value
    )
    el.addEventListener('touchend', () => {
      el.classList.remove(params.css)
    })
    el.addEventListener('touchstart', () => {
      el.classList.add(params.css)
      if (params.time !== -1) {
        setTimeout(() => {
          el.classList.remove(params.css)
        }, params.time)
      }
    })
  }
})
