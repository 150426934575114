import store from '@/store/index'
import { appStorage } from '@/utils/storage'

class HistoryRecord {
  constructor() {
    this.CACHE_KEY = 'router-record'
    this.data = {
      count: 0,
      list: []
    }
    this.storageConfig = {
      storage: sessionStorage
    }
    this.recover()
    this.save()
  }

  getIndex(path) {
    return this.data.list.indexOf(path)
  }

  push(path) {
    this.data.count++
    this.data.list[this.data.count] = path
    this.save()
  }

  recover() {
    this.data = appStorage.get(this.CACHE_KEY, this.data, this.storageConfig)
  }

  save() {
    appStorage.set(this.CACHE_KEY, this.data, this.storageConfig)
  }
}

const Record = new HistoryRecord()

export function direction(to, from) {
  if (!Record.data.list.length) {
    Record.push(from.fullPath)
    store.commit('updateDirection', 'forward')
  }
  // 页面切换方向
  if (to.params.direction) {
    store.commit('updateDirection', to.params.direction)
  } else {
    const toIndex = Record.getIndex(to.fullPath)
    const fromIndex = Record.getIndex(from.fullPath)

    if (toIndex !== -1) {
      if (toIndex > fromIndex) {
        store.commit('updateDirection', 'forward')
      } else {
        store.commit('updateDirection', 'back')
      }
    } else {
      Record.push(to.fullPath)
      store.commit('updateDirection', 'forward')
    }
  }
}

export default {
  beforeRouteLeave(to, from, next) {
    direction(to, from)
    next()
  }
}
