// Vue挂载前置事务处理
import { checkCacheValidity } from '@/utils/storage'
// import disableLog from '@/utils/console'
import 'vant/lib/icon/local.css'
import '@/assets/fonts/index.scss'
import 'tinymce/skins/content/default/content.css'

// 检查缓存
checkCacheValidity()

// 禁用 Log
// disableLog()
