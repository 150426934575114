// URL路径是否包含前缀校验
export const NotURLPrefixRegExp = /^\//
export function getPic(link) {
  if (NotURLPrefixRegExp.test(link)) {
    return import.meta.env.VITE_APP_IMAGE_BASE + link
  }
  return link
}

/**
 * 广告位响应值格式转换函数
 * @param config 广告位响应值
 * @returns {*}
 */
export function adConfigFormatter(config = {}) {
  config = config || {}
  const oExtend = config.o_extend || {}
  return {
    pic: getPic(config?.s_pic),
    link: config?.s_link,
    name: config?.s_picalt || config?.s_remark,
    type: config?.o_extend?.linktype,
    title: config?.s_remark,
    iosHref: config?.o_extend?.iosHref
      ? `https://${config?.o_extend?.iosHref}`
      : '',
    extend: {
      width: config?.o_extend?.width,
      height: config?.o_extend?.height,
      type: config?.o_extend?.linktype,
      power: oExtend?.power,
      tab: oExtend?.tab
    },
    script: config?.s_script ? JSON.parse(config.s_script) : ''
  }
}

/**
 * 该函数用于将Px换算为Vw
 * @param {string | number} px 设计图中元素尺寸
 * @param {string} viewportUnit 转换后单位，默认vw
 * @param {object} config  px2viewport配置项
 * @param {number} config.viewportWidth 设计图尺寸
 * @param {number} config.unitPrecision 转换后保留位数
 * @returns {string} 转换后结果
 */
export function px2viewport(
  px,
  viewportUnit = 'vw',
  config = {
    viewportWidth: 375,
    unitPrecision: 3
  }
) {
  try {
    return (
      ((String(px).replace('px', '') / config.viewportWidth) * 100).toFixed(
        config.unitPrecision
      ) + viewportUnit
    )
  } catch (e) {
    return ''
  }
}
