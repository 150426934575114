import Vue from 'vue'
import { openAppOrWxPage } from '@/utils'
import { getLeaveInfoFlag, setLeaveInfo } from '@/api/modules'
import { LINKS, leaveInfoMap } from '@/data'
const noop = Vue.prototype.noop

/**
 * 留资
 * 基本逻辑：该函数返回一个promise。判断是否已经留过资。
 * 如果未通过检查（未留资/需要留资）则Promise被reject()；如果通过检查（不需要留资）则Promise被reject()
 *
 * @param {Object} defaultConf 留资配置
 * @param {any} params 后续操作所用到的参数
 */

class LeaveInfo {
  defaultConf = {
    source: '',
    params: {},
    beforeRedirect: noop,
    redirect: this.toLeaveInfoPage
  }

  /**
   * 留资函数
   * @param {*} conf 参数配置项
   * @returns Boolean true需要跳转留资
   */
  toLeaveInfoPage({ source }) {
    openAppOrWxPage(LINKS.留资, {
      query: {
        source
      }
    })
  }

  async getLeaveInfoRequest(source) {
    return await getLeaveInfoFlag({ source })
  }

  async doLeaveRequest(data) {
    return await setLeaveInfo(data)
  }

  async doLeave(conf = {}) {
    const {
      source,
      params,
      beforeRedirect, // 跳转之前要做的事情（支持异步）
      redirect
    } = Object.assign({}, this.defaultConf, conf)
    // 规定回调函数返回值
    const callbackParams = {
      params,
      fource: false, // 是否强制留资
      retainedCapital: false, // 是否需要留资。是则跳转留资页面
      info: {}
    }

    try {
      if (!source) {
        throw new ReferenceError('未知的变量source')
      }
      const {
        error_code: code,
        data = {},
        info = {}
      } = await this.getLeaveInfoRequest(source)
      if (code === 0 && data) {
        Object.assign(callbackParams, data)
        Object.assign(callbackParams.info, info)
        const needAutoLeave = this.checkNeedAutoLeave(source, callbackParams)
        // 自动留资
        if (needAutoLeave) {
          const { data = false } = await this.doLeaveRequest(
            callbackParams.info
          )
          if (data) {
            callbackParams.retainedCapital = false
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
    if (callbackParams.retainedCapital) {
      // 需要留资。去留资，并throw new Error
      await beforeRedirect(conf, callbackParams)
      redirect(conf, callbackParams)
      throw new Error('留资跳转，redirect后阻止后续事件执行')
    }
    return callbackParams
  }

  // 检查是否满足自动留资条件
  checkNeedAutoLeave(source, { info }) {
    const { autoLeave, fields } = leaveInfoMap[source] || {}
    if (!autoLeave) return false
    // 检查是否source对应必填的字段info中是否全部存在
    const needAutoLeave = fields.every((field) => {
      return field.required && info[field.key]
    })
    return needAutoLeave
  }

  // 检查是否已经留资(包括自动留资)。已留资返回true
  async checkNeedLeaveInfo(source) {
    const {
      data = {},
      info = {},
      error_code: code = 0
    } = await this.getLeaveInfoRequest(source)
    if (code === 0 && data && data.retainedCapital) {
      // 检查是否满足自动留资条件
      const autoLeave = this.checkNeedAutoLeave(source, { info })
      // autoLeave: true 满足自动留资条件，表示已经留资。
      return autoLeave
    } else {
      return true
    }
  }
}

Vue.prototype.$leaveInfo = new LeaveInfo()
