<template>
  <div
    id="app"
    class="j-container"
    :class="{ 'transparent-header': layoutConf.transparentHeader }"
  >
    <div
      v-if="showOldHeader"
      class="j-header jy-app-header common-app-header"
      :class="{ 'border-line-b': borderShow, 'h5-header': $envs.inH5 }"
      :style="layoutConf.positionStyle"
    >
      <van-icon
        class="header-left"
        :style="layoutConf.actionLeftStyle"
        :class="{ hide: layoutConf.actionLeftHide }"
        name="arrow-left"
        @click="clickLeft"
      />
      <span
        class="header-title"
        v-show="showHeaderTitle"
        :style="layoutConf.titleStyle"
        v-html="layoutConf.title"
      ></span>
      <span
        class="header-right"
        :style="layoutConf.actionRightStyle"
        v-html="layoutConf.actionRightText"
        @click="clickRight"
      ></span>
    </div>
    <van-nav-bar
      v-if="showNewHeader || showHeaderInWX"
      class="j-header van-app-header common-app-header"
      :class="{ 'h5-header': $envs.inH5 || showHeaderInWX }"
      :style="layoutConf.positionStyle"
      :border="borderShow"
      @click-left="clickLeft"
      @click-right="clickRight"
    >
      <template #left>
        <van-icon
          class="header-left"
          :style="layoutConf.actionLeftStyle"
          :class="{ hide: layoutConf.actionLeftHide }"
          name="arrow-left"
        />
      </template>
      <template #title>
        <span
          v-show="showHeaderTitle"
          class="header-title header-title-ellipsis ellipsis"
          :style="layoutConf.titleStyle"
          v-html="layoutConf.title"
        ></span>
      </template>
      <template #right>
        <span
          class="header-right"
          :style="layoutConf.actionRightStyle"
          v-html="layoutConf.actionRightText"
        ></span>
      </template>
    </van-nav-bar>
    <div class="j-main">
      <slot name="default"></slot>
    </div>
    <div class="j-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { NavBar, Icon } from 'vant'
import EventBus from '@/utils/eventBus'

export default {
  name: 'AppLayout',
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon
  },
  data() {
    const { noop } = this
    return {
      useHeader: false,
      useNewHeader: true,
      // 如果微信端actionRight有值。微信端当前页面就显示头部。
      // 可以通过actionRightText传空格让微信端显示头部
      showHeaderInWXThisPage: false,
      layoutConf: {
        // layout标题,优先级高于$route.meta
        title: '',
        transparentHeader: false,
        // 是否隐藏左侧返回按钮
        actionLeftHide: '',
        actionRightText: '',
        hideBorder: false,
        titleStyle: {},
        actionLeftStyle: {},
        actionRightStyle: {},
        onClickLeft: noop,
        onClickRight: noop
      },
      defaultLayoutConf: {
        title: '剑鱼标讯',
        transparentHeader: false,
        actionLeftHide: '',
        actionRightText: '',
        hideBorder: false,
        titleStyle: {},
        actionLeftStyle: {},
        actionRightStyle: {},
        onClickLeft: () => {
          history.back()
        },
        onClickRight: noop
      }
    }
  },
  computed: {
    showOldHeader() {
      return this.useHeader && this.inAppOrH5 && !this.useNewHeader
    },
    showNewHeader() {
      return this.useHeader && this.inAppOrH5 && this.useNewHeader
    },
    showHeaderInWX() {
      const { inWX } = this.$envs
      return this.showHeaderInWXThisPage && inWX
    },
    showHeaderTitle() {
      // 当微信端展示头部时，头部的title部分则隐藏
      return !this.showHeaderInWX
    },
    inAppOrH5() {
      return this.$envs.inAppOrH5
    },
    borderShow() {
      return !this.layoutConf.hideBorder && !this.layoutConf.transparentHeader
    }
  },
  watch: {
    '$route.path': function (to, from) {
      this.getHeaderUseState()
      this.resetLayoutConf()
    },
    '$route.meta': function (to, from) {
      this.getHeaderUseState()
      this.refreshHeader()
    },
    // title 改变自动更新 document.title
    'layoutConf.title': function (n, o) {
      this.setTitle(n)
    }
  },
  created() {
    this.getHeaderUseState()
    this.eventBusListening()
    this.resetLayoutConf()
  },
  methods: {
    checkHeaderShowInWX() {
      const { actionRightText, onClickRight } = this.layoutConf
      const changedRightEvent = onClickRight !== this.noop
      if (actionRightText || changedRightEvent) {
        this.showHeaderInWXThisPage = true
      }
    },
    getHeaderUseState() {
      this.useHeader = this.$route.meta?.header
      return this.useHeader
    },
    clickLeft(e) {
      const { onClickLeft } = this.layoutConf
      onClickLeft && onClickLeft(e)
    },
    clickRight(e) {
      const { onClickRight, actionRightText } = this.layoutConf
      // 如果头部右侧按钮绑定了事件但是文字为空，则不执行事件
      if (actionRightText) {
        onClickRight && onClickRight(e)
      }
    },
    eventBusListening() {
      EventBus.$on('headerConf:merge', (conf) => {
        if (!conf) return
        if (Object.keys(conf).length === 0) return
        this.mergePageConf(conf)
        this.checkHeaderShowInWX()
      })
      EventBus.$on('headerConf:reset', () => {
        this.resetLayoutConf()
      })
      EventBus.$on('headerConf:refreshTitle', () => {
        this.refreshHeader()
      })

      // 及时解绑 EventBus 的事件监听
      this.$once('hook:beforeDestroy', () => {
        EventBus.$off('headerConf:merge')
        EventBus.$off('headerConf:reset')
        EventBus.$off('headerConf:refreshTitle')
      })
    },
    // 从 meta 重新获取 title
    refreshHeader() {
      const { title } = this.$route.meta
      // 修改头部标题
      const setTitle = title || this.defaultLayoutConf.title
      this.layoutConf.title = setTitle
      this.setTitle(setTitle)
    },
    setTitle(title) {
      document.title = title
    },
    mergePageConf(conf) {
      this.getHeaderUseState()
      Object.assign(this.layoutConf, conf)
    },
    resetLayoutConf() {
      // reset 时忽略 title 等
      const ignoreKeys = ['title']
      this.showHeaderInWXThisPage = false
      for (const key in this.layoutConf) {
        if (ignoreKeys.indexOf(key) === -1) {
          this.layoutConf[key] = this.defaultLayoutConf[key]
        }
      }
    }
  }
}
</script>

<style lang="scss">
.j-container {
  height: 100%;
  &.transparent-header {
    .jy-app-header,
    .van-app-header {
      position: absolute;
      width: 100%;
      background-color: transparent;
      .header-left {
        color: #fff;
      }
    }
  }
}

// 全局布局样式
.j-header {
  position: relative;
  width: 100%;
  z-index: 2;

  &.common-app-header {
    z-index: 100;
  }

  // app头部样式
  &.jy-app-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 80px;
    padding: 40px 12px 0;
    min-height: 20px;
    background-color: #fff;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    &.h5-header {
      height: 40px;
      padding: 0 12px;
    }
  }

  // 头部 vant 组件重置样式
  &.van-app-header {
    &.h5-header {
      &.no-border {
        &::after {
          content: unset;
        }
      }
      .van-nav-bar__content {
        height: 40px;
        margin: 0 12px;
      }
    }
    .header-left.van-icon {
      color: #5f5e64;
    }
    .van-nav-bar__content {
      justify-content: space-between;
      height: 40px;
      margin: 40px 12px 0;
    }
    .van-nav-bar__title {
      display: flex;
      align-items: center;
    }
    .van-nav-bar__left,
    .van-nav-bar__right {
      padding: 0;
    }
  }

  .header-left,
  .header-title,
  .header-right {
    display: flex;
    align-items: center;
  }
  .header-left,
  .header-right {
    &.hide {
      visibility: hidden;
    }
  }
  .header-left {
    color: #444;
    font-size: 20px;
  }
  .header-title {
    position: absolute;
    left: 50%;
    display: inline-block;
    color: #171826;
    font-size: 17px;
    transform: translateX(-50%);
  }
  .header-right {
    height: 100%;
    min-width: 20px;
    font-size: 15px;
    color: $color_main;
  }
  // 能够让ellipsis生效的样式
  .header-title-ellipsis {
    display: inline-block;
    max-width: 60%;
  }
}

.j-main {
  font-size: 16px;
  // 所有都j-main开启ios-touch
  -webkit-overflow-scrolling: touch;
  .calc-height-1px {
    height: calc(100% + 1px);
  }

  // 单独关闭ios惯性滑动
  &.no-ios-touch {
    -webkit-overflow-scrolling: auto;
  }

  // &.ios-touch {
  //   // 开启ios惯性滑动，列表的 item 必须由一个根div元素包括
  //   -webkit-overflow-scrolling: touch;
  //   // 解决ios滑动白屏问题
  //   &>.calc-height-1px,
  //   &>div {
  //     height: calc(100% + 1px);
  //   }
  // }
}

.j-footer {
  box-shadow: 0px -2px 8px rgba(54, 147, 179, 0.051);
}
</style>
