import './pre-mount-hooks'
import Vue from 'vue'
import dayjs from 'dayjs'
import MetaInfo from 'vue-meta-info'
import { Dialog, Lazyload, Toast } from 'vant'
import TestBindPhone from '@jy/plugin-bind-phone'
import App from './App.vue'
import router from './router'
import store from './store'
// https://unocss.dev
import 'virtual:uno.css'
import 'vant/lib/index.less'
import '@/assets/style/index.scss'

import 'dayjs/locale/zh-cn'

import '@/utils/prototype'
import '@/utils/mixins'
import '@/utils/directive'
import { initSentry } from './sentry'

dayjs.locale('zh-cn')

Vue.use(Toast).use(Lazyload).use(Dialog).use(MetaInfo).use(TestBindPhone)

// 设置默认 loading 配置项
Toast.setDefaultOptions('loading', {
  forbidClick: true
})

Vue.filter('stripHTML', (value) => {
  const div = document.createElement('div')
  div.innerHTML = value
  const text = div.textContent || div.textContent || ''
  return text
})

Vue.config.productionTip = false

Vue.prototype.$getBase64Image = (img) => {
  const canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height
  const ctx = canvas.getContext('2d')
  ctx.drawImage(img, 0, 0, img.width, img.height)
  let ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase()
  if (ext === 'jpg') {
    ext = 'jpeg' // 这个地方是由于如果是jpg, 他会识别成image/png
  }
  const dataURL = canvas.toDataURL(`image/${ext}`)
  return dataURL
}

// Sentry 异常监控
initSentry(Vue)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
