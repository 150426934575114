import Vue from 'vue'

Vue.directive('keep', {
  update(el, binding, vNode) {
    vNode.data.$keepParams = binding?.value
  },
  inserted: function (el, binding, vNode) {
    vNode.data.$keepParams = binding?.value

    function onClick(e) {
      Vue.prototype.$keep.action(vNode.data.$keepParams)
    }

    el.addEventListener('click', onClick, false)
  }
})
