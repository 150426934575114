import Vue from 'vue'

Vue.directive('tap', {
  bind(el, binding) {
    let startX, startY;
    let isTap = false;

    const handleStart = (e) => {
      if (e.touches) {
        startX = e.touches[0].clientX;
        startY = e.touches[0].clientY;
      } else {
        startX = e.clientX;
        startY = e.clientY;
      }

      isTap = true;

      // 添加点击事件监听器
      el.addEventListener('click', handleClick, { once: true });
    };

    const handleEnd = (e) => {
      if (!isTap) return;

      let currentX, currentY;

      if (e.changedTouches) {
        currentX = e.changedTouches[0].clientX;
        currentY = e.changedTouches[0].clientY;
      } else {
        currentX = e.clientX;
        currentY = e.clientY;
      }

      const moveX = Math.abs(currentX - startX);
      const moveY = Math.abs(currentY - startY);

      if (moveX < 10 && moveY < 10) {
        // 触发 tap 事件
        binding.value();
      }

      isTap = false;
      // 移除点击事件监听器
      el.removeEventListener('click', handleClick);
    };

    const handleClick = (e) => {
      if (!isTap) {
        // 如果 tap 未触发，才触发 click
        binding.arg === 'click' && el.clickHandler(e); // 如果是完整的 click 事件
      }
    };

    // 添加触摸和鼠标事件监听
    el.addEventListener('touchstart', handleStart);
    el.addEventListener('touchend', handleEnd);
    el.addEventListener('mousedown', handleStart);
    el.addEventListener('mouseup', handleEnd);

    // 存储事件处理函数以便在解绑时移除
    el.__vTapHandlers = {
      handleStart,
      handleEnd,
      handleClick
    };
  },
  unbind(el) {
    const handlers = el.__vTapHandlers;
    el.removeEventListener('touchstart', handlers.handleStart);
    el.removeEventListener('touchend', handlers.handleEnd);
    el.removeEventListener('mousedown', handlers.handleStart);
    el.removeEventListener('mouseup', handlers.handleEnd);
    el.removeEventListener('click', handlers.handleClick);

    delete el.__vTapHandlers;
  }
});
