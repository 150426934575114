import * as Sentry from '@sentry/vue'
import { appStorage } from './utils/storage'

export function initSentry(Vue) {
  if (!import.meta.env.DEV) {
    Sentry.init({
      Vue,
      dsn: 'https://f5f712c8f7344bd0976a4354504f9726@jysentry.jydev.jianyu360.cn/4',
      release: import.meta.env.VITE_APP_GIT_BRANCH,
      environment: 'produce',
      sampleRate: 1,
      beforeSend: (event) => {
        let isTest = false
        try {
          isTest = window.navigator.userAgent.lastIndexOf('jy-test') !== -1
        } catch (e) {
          console.warn(e)
        }
        if (isTest) {
          return null
        }
        try {
          event.user.email = appStorage.get('ONLY_CHECK_SESSION', '')
        } catch (e) {
          console.warn(e)
        }
        return event
      }
    })
    Sentry.setTag('url', location.href)
    const id = document.cookie.match(/(^|;)\s*ud_safe\s*=\s*([^;]+)/)
    Sentry.setUser({ id: id && id[2] })
  }
}
