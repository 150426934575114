const ua = navigator.userAgent
const hostname = location.hostname.toLowerCase()

// 在安卓或者ios中
export const androidOrIOS = function () {
  const u = ua.toLowerCase()
  let agent = ''
  if (/iphone|ipod|ipad|ios/.test(u)) {
    agent = 'ios'
  } else {
    agent = 'android'
  }
  return agent
}

/**
 * 判断是否存在新版APP浏览器UA（webview合一版本）
 * @returns {boolean}
 */
export const getIsInTheUnifyAppContainer = function () {
  const u = ua.toLowerCase()
  let inNewApp = false

  if (u.includes('jianyuapp')) {
    inNewApp = true
  }
  return inNewApp
}

/**
 * 用于判断是否在APP容器内
 * @returns {boolean}
 */
export const getIsInTheAppContainer = function () {
  // 判断是否存在新版APP浏览器UA
  let inApp = getIsInTheUnifyAppContainer()

  if (inApp) {
    return true
  }

  if (window.JyObj && window.JyObj.mock) {
    return inApp
  }
  try {
    if (androidOrIOS() === 'ios') {
      const iniOSApp =
        typeof window.webkit.messageHandlers.skipAppointTab.postMessage ===
        'function'
      inApp = iniOSApp
    } else {
      const inAndroidApp = typeof window.JyObj !== 'undefined'
      inApp = inAndroidApp
    }
  } catch (e) {
    console.warn(e)
    inApp = false
  }
  return inApp
}

export function getIsH5HostName() {
  return hostname.includes('h5')
}

// 判断是否是微信浏览器
export const inWeiXinBrowser = ua.toLowerCase().includes('micromessenger')
export const inWeiXinMiniApp = ua.toLowerCase().includes('miniprogram')
const platformOS = androidOrIOS()
const inApp = getIsInTheAppContainer()

export function getPlatform() {
  const h5host = getIsH5HostName()
  if (inApp) {
    return 'app'
  } else if (h5host) {
    return 'h5'
  } else if (inWeiXinBrowser) {
    return 'wx'
  } else {
    return 'h5'
  }
}

// 存放基本变量的集合
const _env = {
  ua,
  platformOS,
  platform: getPlatform()
}

// 对基本变量扩展计算的集合
const inH5 = _env.platform === 'h5'
const _envs = {
  inWX: _env.platform === 'wx',
  inH5,
  inWxMini: inWeiXinMiniApp,
  inApp,
  inUnifyApp: getIsInTheUnifyAppContainer(),
  inAppOrH5: inApp || inH5,
  inAndroid: _env.platformOS === 'android',
  inIOS: _env.platformOS === 'ios'
}

export const env = _env
export const envs = _envs
