import Cookies from 'js-cookie'

const BindPhoneDirective = {
  inserted(el, binding, vNode) {
    const { value } = binding
    const vm = vNode.context

    const clickHandler = async (event) => {
      // event.isProgrammaticClick = false
      event.stopPropagation()
      event.preventDefault()
      const { props = {}, pass, bound, close, next } = value
      /**
       * props: 接受的传参
       * pass: 无需校验是否绑定即可进行下一步，场景：金刚区（部分产品需要绑定手机号、部分产品不需要绑定手机号）
       * next: 绑定过手机号的下一步操作(必传)
       * close: 关闭弹框操作
       * bound：弹框绑定完手机号回调操作（大多数场景与next方法逻辑一致，部分场景有额外需求的会不一致,当绑定完成回调与next不一致时，需要传入bound方法）
       */
      console.log(
        `pass: ${pass}, bound: ${bound},next: ${next}, props: ${JSON.stringify(
          props
        )}`
      )

      // if (event.isProgrammaticClick) {
      //   event.isProgrammaticClick = false // 重置标志
      //   return // 阻止递归
      // }

      // 无需校验/无需绑定手机号
      if (typeof pass === 'function') {
        pass()
        return
      }

      // 从cookie中获取是否绑定过手机号
      const needPhoneBound = Cookies.get('EXPERIENCESIGN') === 'experiencing'
      // 绑定成功cookie里塞的值
      const alreadyBind = Cookies.get('EXPERIENCE')
      // 已绑定过手机号
      if (!needPhoneBound) {
        if (typeof next === 'function') {
          next()
        } else {
          // event.stopImmediatePropagation()
          // event.target.dispatchEvent(new Event('click', { bubbles: true }))
          // event.isProgrammaticClick = true // 设置标志
          // event.target.click() // 触发不了
        }
      } else {
        if (alreadyBind) {
          return next?.()
        }
        props.visible = true
        // 未绑定过手机号
        vm.$bindPhoneDialog({
          props: props,
          slots: {},
          on: {
            bound: () => {
              // 绑定完清除cookie
              Cookies.remove('EXPERIENCESIGN')
              // 绑定成功埋点
              try {
                window.__EasyJTrack.addTrack(props.name, {
                  break_data: 'abtest',
                  source: props.name + '-绑定成功'
                })
              } catch (error) {}

              if (bound && typeof bound === 'function') {
                bound()
              } else {
                next?.()
              }
            },
            close: () => {
              if (typeof close === 'function') {
                close()
              } else {
                vm.$toast('请先绑定手机号')
              }
            }
          }
        })
      }
    }
    // 绑定主点击事件
    el.addEventListener('click', clickHandler, true)
    el._clickHandler = clickHandler
  },
  unbind(el) {
    el.removeEventListener('click', el._clickHandler)
  }
}

export default BindPhoneDirective
